// extracted by mini-css-extract-plugin
export var column = "MoneyField__column__oQbao";
export var flex = "MoneyField__flex___3XDN";
export var flexColumn = "MoneyField__flexColumn__VyQkd";
export var gap1 = "MoneyField__gap1__jo6ql";
export var gap2 = "MoneyField__gap2__qtLVU";
export var gap3 = "MoneyField__gap3___3FPJ";
export var gap4 = "MoneyField__gap4__mEZ56";
export var gap5 = "MoneyField__gap5__XM2Op";
export var input = "MoneyField__input__ggRa9";
export var row = "MoneyField__row__kwou1";
export var wrapper = "MoneyField__wrapper___mgZx";